<template>
  <main 
  v-if="page"
  id="content" 
  aria-labelledby="main-title"
  >
    
    <!-- <ElementHeroHome v-if="page.hero" :content="page.hero" /> -->

    <ElementHero v-if="page.hero" :content="page.hero" />

    <template v-if="page?.dynamic">
      <template v-for="(section, index) in page?.dynamic">
        <component 
        :is="getSectionComponent(section.collection)" 
        :sectionIndex="index"
        :sectionTotal="page?.dynamic.length"
        :content="section.item"
        />
      </template>
    </template>

  </main>
</template>

<script setup>

const { $seo } = useNuxtApp()
const route = useRoute()
const contentSections = useContentSections()

const { getItems } = useDirectusItems()
const fields = useFields()
const { data } = await useAsyncData(
  'page-home',
  () => getItems({
    collection: 'home',
    params: {
      fields
    }
  })
)

const page = data?.value

const seo = computed(() => {
  if (page?.seo) {
    return $seo(page.seo, route.fullPath)
  }
  return {}
})

useHead(seo)

const getSectionComponent = key => {
  return contentSections[key]
}

</script>